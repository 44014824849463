<template>
    <div>
        <LiefengContent :isBack="true" @backClick="backClick">
            <template v-slot:title>{{ "签署记录" }}</template>
            <template v-slot:toolsbarRight>
                <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                    <FormItem label="提交人" :label-width="100" style="margin-bottom: 0">
                        <Input v-model.trim="searchData.title" placeholder="请输入提交人" style="width: 160px"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="getList" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
        <!-- 查看详情界面 -->
        <LiefengModal title="查看详情" :value="addStatus" @input="openStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <div class="content-view">
                    <div class="content-title">{{ $route.query.title || "暂无标题" }}</div>
                    <div class="content-center" id="content-center" v-html="detailMessage.content"></div>
                    <div class="content-bottom">
                        <div class="image-div">
                            <div class="register-name">{{ name }}</div>
                            <div class="image">
                                <img :src="detailMessage.signatureImg" class="img" @click="hanlderImage" />
                                <img class="remark-img" v-if="detailMessage.cachetStatus == '1' && detailMessage.contentImg && detailMessage.contentImg" :src="detailMessage.contentImg" alt="" />
                            </div>
                            <div class="time">{{ detailMessage.gmtCreate && detailMessage.gmtCreate != '' && detailMessage.gmtCreate.indexOf('年') > -1 ?
                                 detailMessage.gmtCreate
                                 : $core.formatDate(new Date(detailMessage.gmtCreate),'yyyy年MM月dd日')}}</div>
                        </div>
                    </div>
                    <div class="content-desc">
                        {{ remark || "" }}
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-left: 10px" type="primary" v-if="cachet != '' && showStatus && showStatus == '2'" @click="clickStatus()">上传电子印章</Button>
                <Button style="margin-left: 10px" type="primary" v-if="showStatus && showStatus == '2'" @click="clickStatus()">上传盖章后的图片</Button>
                <!-- <Button style="margin-left: 10px" type="primary" @click="exportHtml">下载</Button> -->
                <Button style="margin-left: 10px" type="primary" @click="exportHtml">打印</Button>
                <Button style="margin-left: 10px" type="primary" @click="cancelAdd">确定</Button>
            </template>
        </LiefengModal>
        <!-- 查看处理模态框 -->
        <LiefengModal title="处理状态" :value="statusModal" @input="statusModalFn" :fullscreen="false">
            <template v-slot:contentarea>
                <div>
                    <Form :label-colon="true">
                        <FormItem :label-width="100">
                            <span slot="label" class="validate">处理方式:</span>
                            <RadioGroup v-model="selectStaus">
                                <!-- <Radio label="1" :disabled="!cachet || cachet == ''"> -->
                                <Radio label="1" :disabled="!cachet || cachet == ''">
                                    <span>盖电子章</span>
                                </Radio>
                                <Radio label="2">
                                    <span>上传盖章后的图片</span>
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem :label-width="100" v-if="selectStaus && selectStaus == '2'">
                            <span slot="label" class="validate">上传图片:</span>
                            <LiefengUpload
                                ref="imgData"
                                v-if="showImageUpload"
                                accept=".jpg,.png,.gif,.jpeg"
                                :format="['jpg', 'png', 'jpeg', 'gif']"
                                :defaultList="imgData"
                                :fileLength="1000"
                                :showView="true"
                            ></LiefengUpload>
                            <span>请上传盖章后的图片，可多张</span>
                        </FormItem>
                    </Form>
                </div>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button style="margin-left: 10px" type="primary" @click="exportHtml">下载</Button> -->
                <Button style="margin-left: 10px" type="primary" @click="statusModalFn(false)">取消</Button>
                <Button style="margin-left: 10px" type="primary" @click="saveStatus">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: { LiefengContent, LiefengTable, LiefengModal, LiefengUpload },
    data() {
        return {
            searchData: { title: "" },
            // table表格数据
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0,
            talbeColumns: [
                {
                    title: "提交人",
                    align: "center",
                    key: "userName",
                },
                {
                    title: "提交时间",
                    align: "center",
                    key: "gmtCreate",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "提交账号",
                    align: "center",
                    key: "mobile",
                },
                {
                    title: "所属区",
                    align: "center",
                    key: "zoneName",
                },
                {
                    title: "所属街道",
                    align: "center",
                    key: "streetName",
                },
                {
                    title: "所属社区",
                    align: "center",
                    key: "communityName",
                },
                {
                    title: "处理状态",
                    align: "center",
                    key: "status",
                    render: (h, params) => {
                        return (
                            "div",
                            [
                                h("Checkbox", {
                                    attrs: {
                                        value: params.row.status == 2 ? false : true,
                                    },
                                    on: {
                                        "on-change": status => {
                                            this.$core.debounce(
                                                async () => {
                                                    this.$Message.loading({
                                                        content: "正在加载数据",
                                                        background: true,
                                                    })
                                                    await this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectUserRecord", {
                                                        userAccordId: params.row.id,
                                                    }).then(async res => {
                                                        this.$Message.destroy()
                                                        if (res.code == 200 && res.data) {
                                                            await this.$post(
                                                                "/gateway/syinfopublish/api/pc/applyAccord/changeUserRecord",
                                                                {
                                                                    userAccordId: params.row.id,
                                                                    status: status == true ? 1 : 2,
                                                                    cachetStatus: !res.data.cachetStatus || res.data.cachetStatus == "" ? "2" : res.data.cachetStatus, // 1表示已盖章,2表示上传图片
                                                                    contentImg: res.data.contentImg,
                                                                },
                                                                { "Context-Type": "Application/json" }
                                                            ).then(res => {
                                                                if (res && res.code == 200) {
                                                                    this.$Message.success({
                                                                        content: "设置成功",
                                                                        background: true,
                                                                    })
                                                                    this.page = this.currentPage
                                                                    this.getList()
                                                                    return
                                                                } else {
                                                                    this.$Message.error({
                                                                        content: "设置失败",
                                                                        background: true,
                                                                    })
                                                                    this.page = this.currentPage
                                                                    this.getList()
                                                                }
                                                            })
                                                        } else {
                                                            this.$Message.error({
                                                                content: "获取数据失败",
                                                                background: true,
                                                            })
                                                            return
                                                        }
                                                    })
                                                },
                                                "timer",
                                                1000
                                            )
                                        },
                                    },
                                }),
                                "已处理",
                            ]
                        )
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    width: 250,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.statusParams = params
                                                this.showStatus = params.row.status
                                                this.userAccordId = params.row.id
                                                await this.getDetail(params.row.id)
                                                await this.getFileDatil()
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "查看详情" //1001
                                ),
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                 this.userAccordId = params.row.id
                                //                 await this.getDetail(params.row.id)
                                //                 await this.getFileDatil()
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "下载" //1001
                                // ),

                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.statusParams = params
                                                this.showStatus = params.row.status
                                                this.userAccordId = params.row.id
                                                await this.getDetail(params.row.id)
                                                await this.getFileDatil()
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "打印" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                await this.getFileDatil()
                                                await this.changeStatus(params)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            display: params.row.status == "2" ? "inline" : "none",
                                        },
                                    },
                                    "处理"
                                ),
                            ]
                        )
                    },
                },
            ],

            // 新增修改模态框控制
            addStatus: false,
            timer: null,

            detailMessage: {},
            name: "",
            userAccordId: "",

            // 处理的模态框
            statusModal: false,

            selectStaus: "2",

            showImageUpload: false,
            imgData: [],
            showStatus: "",

            statusParams: {},

            cachet: "", // 印章图片

            remark: "",
        }
    },
    async created() {
        await this.getList()
    },
    methods: {
        async clickStatus() {
            await this.changeStatus(this.statusParams)
        },
        async changeStatus(params) {
            this.userAccordId = params.row.id
            this.$Message.loading({
                content: "正在加载数据",
                background: true,
            })
            await this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectUserRecord", {
                userAccordId: this.userAccordId,
            }).then(res => {
                this.$Message.destroy()
                console.log("this.cachet", this.cachet)
                if (this.cachet && this.cachet != "") {
                    this.selectStaus = "1"
                    this.showImageUpload = false
                    this.$nextTick(() => {
                        this.showImageUpload = true
                        this.imgData = []
                    })
                    return
                }
                if (res.code == 200 && res.data && res.data.contentImg && res.data.contentImg != "") {
                    this.showImageUpload = false
                    this.selectStaus = res.data.cachetStatus
                    this.$nextTick(() => {
                        this.imgData = []
                        this.showImageUpload = true
                        res.data.contentImg.split(",").map(item => {
                            this.imgData.push({
                                type: "image",
                                url: item,
                            })
                        })
                    })
                } else {
                    this.selectStaus = "2"
                    this.showImageUpload = false
                    this.$nextTick(() => {
                        this.showImageUpload = true
                        this.imgData = []
                    })
                }
            })
            this.statusModalFn(true)
        },
        // 方法部分
        saveStatus() {
            if (this.selectStaus) {
                // 上传图片的逻辑
                if (this.selectStaus == "2") {
                    if (this.$refs.imgData.uploadList && this.$refs.imgData.uploadList.length) {
                        let list = []
                        this.$refs.imgData.uploadList.map(item => {
                            list.push(item.url)
                        })
                        this.$post(
                            "/gateway/syinfopublish/api/pc/applyAccord/changeUserRecord",
                            {
                                userAccordId: this.userAccordId,
                                status: "1", // 1表示已处理
                                cachetStatus: this.selectStaus, // 1表示已盖章,2表示上传图片
                                contentImg: list.join(","),
                            },
                            { "Context-Type": "Application/json" }
                        ).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "操作成功",
                                    background: true,
                                })
                                this.page = this.currentPage
                                this.showStatus = false
                                this.statusModalFn(false)
                                this.getList()
                                return
                            } else {
                                this.$Message.error({
                                    content: "操作失败",
                                    background: true,
                                })
                                return
                            }
                        })
                    } else {
                        this.$Message.warning({
                            content: "请上传图片",
                            background: true,
                        })
                        return
                    }
                } else if (this.selectStaus == "1") {
                    this.$post(
                        "/gateway/syinfopublish/api/pc/applyAccord/changeUserRecord",
                        {
                            userAccordId: this.userAccordId,
                            status: "1", // 1表示已处理
                            cachetStatus: this.selectStaus, // 1表示已盖章,2表示上传图片
                            contentImg: this.cachet,
                        },
                        { "Context-Type": "Application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "操作成功",
                                background: true,
                            })
                            this.page = this.currentPage
                            this.showStatus = false
                            this.statusModalFn(false)
                            this.openStatusFn(false)
                            this.getList()
                            return
                        } else {
                            this.$Message.error({
                                content: "操作失败",
                                background: true,
                            })
                            return
                        }
                    })
                }
            } else {
                this.$Message.warning({
                    content: "请选择处理方式",
                    background: true,
                })
                return
            }
        },
        statusModalFn(status) {
            this.statusModal = status
        },
        backClick() {
            this.$router.push("/autographindex")
            var index = parent.layer.getFrameIndex(window.name) //获取当前窗口的name
            if (index) parent.layer.close(index) //关闭窗口
        },
        hanlderImage() {},
        exportHtml() {
            let dev = ""
            if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "testbuild" || process.env.NODE_ENV == "dev") {
                dev = "test"
            } else {
                dev = "pord"
            }
            // window.open(`http://liefeng.oss-cn-shenzhen.aliyuncs.com/TEST/zjsm/20221215/363071174.html?dev=${dev}&token=${window.sessionStorage.getItem("accessToken")}&accordId=${this.$route.query.id}&userAccordId=${this.userAccordId}`)
            // return
            window.open(
                `http://liefeng.oss-cn-shenzhen.aliyuncs.com/TEST/zjsm/20221228/281090655.html?dev=${dev}&token=${window.sessionStorage.getItem("accessToken")}&accordId=${
                    this.$route.query.id
                }&userAccordId=${this.userAccordId}`
            )
        },

        // 获取当前文件的详情，获得标题和签署人文案
        async getFileDatil() {
            await this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectAccord", {
                accordId: this.$route.query.id,
            }).then(res => {
                if (res && res.code == 200 && res.data.dataJson) {
                    let data = JSON.parse(res.data.dataJson)

                    this.detailMessage.title = res.data.title
                    this.name = data.name
                    this.cachet = res.data.cachet
                    this.remark = res.data.remark
                }
            })
        },
        // 保存按钮
        async getDetail(userAccordId) {
            await this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectUserRecord", {
                userAccordId,
            }).then(res => {
                if (res && res.code == 200 && res.data) {
                    this.openStatusFn(true)
                    if (res.data.dataJson && res.data.dataJson != "") {
                        let data = JSON.parse(res.data.dataJson)
                        let reg = new RegExp('ew">', "g")
                        let regs = new RegExp('ew" >', "g")
                        this.detailMessage = {
                            ...data,
                            content: this.formatInput(data.content).replace(/input/g, "span").replace(reg, `ew" class="span-view"></span>`).replace(regs, `ew" class="span-view"></span>`),
                            cachetStatus: res.data.cachetStatus || "",
                            contentImg: res.data.contentImg || "",
                            status: res.data.status,
                        }
                        console.log("data22222", { ...this.detailMessage })
                        this.$nextTick(() => {
                            var obj = document.getElementsByClassName("span-view")
                            if (obj && obj.length) {
                                for (var i = 0; i < obj.length; i++) {
                                    obj[i].className = `${obj[i].className} value${i}`
                                    for (const [key, value] of Object.entries(this.detailMessage)) {
                                        if (obj[i].className.indexOf(key) > -1) {
                                            obj[i].innerText = value
                                        }
                                    }
                                }
                            }
                        })
                    }
                } else {
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
        formatInput(html) {
            var newContent = html.replace(/<input[^>]*>/gi, function (match, capture) {
                var match = match.replace(/style\s*?=\s*?(['"])[\s\S]*?\1/g, "")
                return match
            })
            return newContent
        },

        cancelAdd() {
            this.openStatusFn(false)
        },
        // 重置按钮
        resetBtn() {
            this.searchData.title = ""
            this.page = 1
            this.getList()
        },
        // 控制新增修改模态框
        openStatusFn(status) {
            this.addStatus = status
        },
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectUserPage", {
                accordId: this.$route.query.id,
                page: this.page,
                pageSize: this.pageSize,
                userName: this.searchData.title,
            }).then(res => {
                this.loading = false
                if (res && res.code && res.code == 200 && res.dataList) {
                    this.tableData = []
                    this.$nextTick(() => {
                        this.tableData = res.dataList
                    })
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
<style lang="less">
.operation-title {
    &::after {
        content: ">";
        position: "absolute";
        transform: rotate(90deg);
        display: block;
        margin-left: 2px;
        font-size: 10px;
    }
}
</style>
<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.content-view {
    width: 794px;
    margin: 0 auto;
    .content-title {
        padding: 20px;
        text-align: center;
        font-size: 23px;
        font-weight: bold;
    }
    .content-center {
        padding: 0 10px;
        letter-spacing: 3px;
        line-height: 26px;
        text-align: justify;
        word-break: break-all;
        font-size: 20px;
        /deep/p,
        /deep/span,
        /deep/u {
            font-size: 16pt;
            letter-spacing: 3px !important;
            line-height: 26px !important;
            text-align: justify;
            word-break: break-all;
            text-decoration: none !important;
            // text-decoration-color: orange;
        }
        /deep/span {
            letter-spacing: 3px !important;
            line-height: 26px !important;
            text-align: justify;
            word-break: break-all;
            text-decoration: none !important;
            color: none !important;
        }
        /deep/.span-view {
            padding: 0 10px;
            color: #1046c5;
            // font-size: 16pt;
            letter-spacing: 3px !important;
            line-height: 26px !important;
            text-align: justify;
            word-break: break-all;
            text-decoration: none !important;
        }
        /deep/#insert-view {
            max-width: 30%;
            display: block;
            margin: 20px auto;
        }
    }
    .content-bottom {
        text-align: right;
        padding: 0px 20px;
        .image-div {
            display: flex;
            margin-left: auto;
            position: relative;
            .register-name {
                margin-left: auto;
                position: absolute;
                right: 16%;
                top: 45%;
            }
            .image {
                margin-left: auto;
                width: 9%;
                position: relative;
                .img {
                    width: 100%;
                    height: 100%;
                    transform: rotate(90deg);
                }
                .remark-img {
                    position: absolute;
                    width: 120px;
                    height: 120px;
                    top: -35px;
                    left: -60px;
                    z-index: 1;
                }
            }
        }
        .time {
            position: absolute;
            top: 88%;
            right: -8px;
        }
    }
    .content-desc {
        margin: 20px 0;
    }
}

/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
</style>